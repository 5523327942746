.Main-func{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;  
  
}

.temp-area-func{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; 
    gap: 3rem;
    width: 100%;

}
.func-btn-area{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
/* @media (max-width:1060px) {
    .temp-area{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap; 
    }
} */