.News{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   
}
.News h2{
    font-size: 2.5rem;
   
}
.News .news-list{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
  max-width: 1000px;
    overflow-x: hidden;
    scroll-behavior: smooth;
    text-align: justify;
}
.News .news-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;  
}
.news-item p{
    color: #525252;
    font-size: 15px;
    text-align: justify;
}
.arrows{
    margin-top: 3rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;

}
.arrows img{
    cursor: pointer;
}
@media (max-width:1050px) {
 
    .News .news-list{
       
        max-width: 650px;
     ;
    
    }
}
@media (max-width:700px) {
   
    .News .news-list{
       
        max-width: 330px;
     ;
    
    }
    .News h2{
        font-size: 2rem;
     
    }
}