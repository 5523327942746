.News-two{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: white;
   height: 50vh;
}
.for-smallest{
    display: none;
}
 .news-list-two{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0rem;
  width: 1300px;
  height: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    text-align: justify;
}
.news-list-two .news-item-two{
    display: flex;
    align-items: center;
    width: 100%;      

}
.news-item-two h3{
  border-left: 8px solid #ed5009;
  padding-left: 0.5rem;
  width: 70%;
  font-weight: 600;
}
.arrows{
    margin-top: 3rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;

}
.arrows img{
    cursor: pointer;
}
@media (max-width:1300px) {
    .news-list-two{
        width: 1000px;        
    }
    .News-two{
      
        height: 70vh;
     }
    
}

@media (max-width:1050px) {
    .news-list-two{
        width: 900px;        
    }

}
@media (max-width:900px) {
    .news-list-two{
        width: 700px;        
    }
    .news-item-two img{
            height: 150px;
    }
}
@media (max-width:710px) {
.for-largest{
    display: none;
}
   .for-smallest{
    display: flex;
    align-items: center;
    justify-content: center;
   }
  
}