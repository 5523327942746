.Colab{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    position: relative;
}

.Colab h1 {
    width: 800px;
    font-size: 2rem;
}
.Colab img{
    height: 300px;
 
}
.Colab .forsmall{
    display: none;
}

.large{
    display: flex;
  
}
.large  p{
    background-color: white;
    width: 60%;
    height: 3.5rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.7rem;
    box-shadow: 0px 0px 15px #63636347; 
    text-align: start;
}
.first-p{
    display: flex;
    justify-content: flex-end;
}

.large  p img{
    height: 40px;
}
.second-p{
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
@media (max-width:1400px) {
    .large  p{ 
        width: 80%;       
    }
}
@media (max-width:900px) {
    .Colab .forsmall{
        display:flex;
    }
.large{
    display: none;
}
.Colab h1 {
    width: 500px;

}
.Colab img{
    height: 300px;
}

}
@media (max-width:1600px) {
    .Colab .txt-1{   
        left: 31%;     
    }
    .Colab .txt-2{
        right: 28%;       
    }
}
@media (max-width:1400px) {
    .Colab .txt-1{
        left: 27%;   
    }
    .Colab .txt-2{
        right: 23%;    
    }
}
@media (max-width:500px) {

    .Colab h1 {
        /* width: 380px;
        font-weight: 400; */
        width: 95%;
        font-size: 1.5rem;
    }
    .Colab img{
        height: 180px;
    }   
}