.responsive{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: absolute;
    top: 5rem;
    right: 1rem;
    height: auto;
    width: 220px;
  
    border-bottom: 2px solid #ED5009;
    border-right: 2px solid #ED5009; 
    border-bottom-right-radius: 2rem;
   
    z-index: 2;
}
.responsive ul{
    display: flex;
    flex-direction: column;  
    justify-content: space-evenly;
    height: 100%;
    
}
.responsive li{
    list-style: none;
    font-size: 1.3rem;
  
    height: 70px;
    width: 99%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 1rem;
    transition: 0.2s ease-in;
  
    border-bottom-right-radius: 2rem;
    text-align: start;
}
.responsive li:hover{
    background-color: #FBF8F0;
}
.projects li{
    padding-left: 3rem;
   
}