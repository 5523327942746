.Previous{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.Previous h2{
    font-size: 2rem;
    border-left: 8px solid #ed5009;
    padding-left: 0.3rem;
    width: 92%;
    display: flex;
}

.Previous .event-list, .Previous .hide{
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: center;

}
 .event-list .event-item{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: start;
    
}
.event-list .event-item h3{
    font-size: 1.15rem ;
    font-weight: 700;
    letter-spacing: 0.5px;
}
.event-list .event-item img{
    height: 13rem;
    width: 20rem;
    border-radius: 0.7rem;
    cursor: pointer;
    transition: 0.2s ease-in;
    object-fit: cover;
}
.event-list .event-item img:hover{
    transform: scale(1.1);
}

.Previous .newspaper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.Previous .artical-area , .Previous .hideNews {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    width: 100%;

}
.artical-area .artical{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
 
    width: 350px;
    margin-bottom: 2rem;
    gap: 0.2rem;
    text-align: start;
}
.artical-area .artical h3{
    font-size: 1.15rem ;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: start;

    width: 90%;
}

.artical-area .artical p{
  
    width: 90%;
}
.artical-area .artical img{
    object-fit: contain;
    height: 100%;
    width: 300px;
   
}
@media (max-width:1550px) {
    .event-list .event-item img{
        height: 9rem;
        width: 14rem;
        
    }
}
@media (max-width:1300px) {
    .artical-area .artical{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        margin-bottom: 2rem;
        
    }
    
    .artical-area .artical img{
        object-fit: contain;
        height: 200px;
    }
}
@media (max-width:740px) {
    .Previous h2, .newspaper h2{
     
        border-left: none;
        justify-content: center;
    }
}