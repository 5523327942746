.combines{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    gap: 1.5rem;

}

.combines h1{
    font-size: 1.5rem;
    font-weight: bold;
}

.combines .com-area{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;

}
.comb{
    padding: 1rem;
    background-color: white ;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    gap: 1rem;
    flex-direction: column;
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 1);
}
.comb h3{
    font-size: 1rem;
}