.high {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  
}
.high-img img{
    height: 350px;
 
}
.high-area{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    gap: 2rem;
}
.high-area .high-text{
    display: flex;
    flex-direction: column;
background-color: white;
    justify-content: center;
    width: 40%;
    height: 20rem;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 15px #63636347 ;
    gap: 2rem;
    padding: 0 1rem;
}
.high-area .high-text .high-item{
    display: flex;
    align-items: center;
}
.high-area .high-text .high-item img{
    height: 40px;
}
@media (max-width:1100px) {

   
}

@media (max-width:900px) {
    .high-area{
        flex-direction: column;
    }
}
@media (max-width:600px) {
    .high-img img{
        height: 230px;
     
    }
    .high-area .high-text{

        width: 60%;
        height: 15rem;
        gap: 1rem;
   
    }
    .high-area .high-text .high-item  p{
        font-size: 0.7rem;
    }
    .high-area .high-text .high-item img{
        height: 30px;
     
    }
}