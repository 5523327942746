
.Seniors-orange-area{
    background-color: #ED5009;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Seniors-orange-area img{
    height: 300px;
    position: absolute;
    left: 20%;
    top: -100px;
}

.Seniors-or-area{
position: absolute;
right: 20%;
top: -60px;
text-align: start;
display: flex;
flex-direction: column;
gap: 3rem;
}
.Seniors-grp-area{
    margin-top: 120px ;
    margin-bottom: 230px;
}
@media (max-width:1300px) {

}
@media (max-width:500px) {
    .Seniors-grp-area{
        margin-bottom: 290px;
    }
    .Seniors-or-area{
        top: -17px;
        gap: 1rem;
        right: 1%;
        z-index: 1;
        }
        .Seniors-or-area .koa{
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
        }
        .Seniors-or-area .koa p{
            font-size: small;
        }
        .Seniors-orange-area img{
           
            height: 300px; 
            right: 0%;
            left: 0%;
            margin-right: 44%;
            
        }
        .Seniors-or-area{
            text-align: end;
        }
        
   
}