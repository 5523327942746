.Nature{
    display: flex;
   flex-direction: column;
}
.natural-wrapper{
    display: flex;
    width: 100%;
}
.Nature .natural-text{
    flex: 2 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    text-align: justify;
    margin-left: 3rem;
}
.Nature .natural-text h1{
  
    font-size: 2rem;
}
.Nature .natural-text p{
    width: 600px;
    word-spacing: 1px;
    letter-spacing: 1px;
  
    font-size: 15px;
    color: #525252;

  
}
.Nature .natural-img{
    flex: 1 1 0;
    display: flex;
   justify-content: flex-end;
   align-items: center;
}
.Nature .natural-img img{
    height: 500px;
}
.natural-small-img{
    display: none;
}
@media (max-width:930px) {
    .natural-big-img{
        display: none;
    }
    .natural-small-img{
        display: flex;
    }
    .Nature .natural-img img{
        height: 400px;
    }
    .Nature .natural-text p{
        width: 400px;
       
    }
   
}
@media (max-width:750px) {
  
    .Nature .natural-img img{
        height: 300px;
    }
    .Nature .natural-text p{
        width: 400px;
       text-align: start;
    }
    .Nature .natural-text h1{
        text-align: start;
        width: 400px;
       
    }
 
}
@media (max-width:620px) {
    .natural-wrapper{
  
        flex-direction: column;
       
    }
    .Nature .natural-text h1{
        text-align: start;
        width: 400px;
    }
    .Nature .natural-text p{
       width: 80%;
      
    }
}