.Naturaltwo{
    display: flex;
}
.Naturaltwo .second-img{
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.Naturaltwo .second-img img{
    height: 500px;
}
.Naturaltwo .second-nature-text{
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
   gap: 2rem;;
   text-align: end;
   margin-right: 3rem;


}
.Naturaltwo .second-nature-text p{
    width: 600px;
    word-spacing: 1px;
    letter-spacing: 1px;
    text-align: justify;
    color: #525252;
font-size: 15px;
    font-family: 'Lora', serif;
    font-family: 'Urbanist', sans-serif;
}
.Naturaltwo .second-nature-text h1{

    font-size: 2rem;
    width: 500px;
}
.natural-small-img{
    display: none;
}

@media (max-width:930px) {
    .natural-big-img{
        display: none;
    }
    .natural-small-img{
        display: flex;
    }
    .Naturaltwo .second-img img{
        height: 400px;
    }
    .Naturaltwo .second-nature-text p{
        width: 400px;
       
    }
    .Naturaltwo .second-nature-text h1{
        width: 300px;
      
     
       
    }
}
@media (max-width:750px) {
  
    .Naturaltwo .second-img img{
        height: 300px;
    }
    .Naturaltwo .second-nature-text p{
        width: 400px;
       text-align: start;
    }
    .Naturaltwo .second-nature-text h1{
        text-align: start;
        width: 400px;
    }
 
}
@media (max-width:620px) {
    .Naturaltwo{
  
        flex-direction: column;
       
    }
    .Naturaltwo .second-nature-text{
        width: 100%;
        
    }
    .Naturaltwo .second-nature-text h1{
        text-align: center;
        width: 95%;
       margin-top: 1rem;
   
    }
    .Naturaltwo .second-nature-text p{
        text-align: end;
   width: 300px;
   margin-right: 2rem;
    }
}