.ourtwo{
 margin-top: 0rem;
display: grid;
grid-template-columns: 1fr 1fr;   
}
.ourtwo .our{
    display: flex;
    flex-direction: column;
    align-items: center ;
}

.ourtwo .second-img{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
   
}
.ourtwo .second-text  {
display: flex;
flex-direction: column;
align-items: flex-end;
justify-content: center;
text-align: justify;
margin-right: 2rem;
gap: 0.7rem;
}
.ourtwo .second-text h1{
    font-size:40px ;
    color: #B20149;
}
.ourtwo .second-text .medex{
    color: #B20149;
}
.ourtwo .second-text h3{
    font-weight: 600;
    color: #121942;
    font-size: 25px;
}
.ourtwo .second-text p{
    color: #525252;
    font-size: 15px;
}
.ourtwo .second-text button{
    height: 50px;
    width: 200px;
    border: 1px solid #ED5009;
    background-color: #4E62D6;
    color: white;
    border-radius: 2px;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
}
@media (max-width:900px){
    .second-img{
    
        width: 300px;
    }
    .second-img img{
        height: 300px;
    }
    .ourtwo{
       grid-template-columns: 1fr 2fr;   
       }
       .ourtwo .second-text  {
        
        margin-right: 1rem;
    
        }
}
@media (max-width:500px){
    .ourtwo{
    gap: 1rem;
    display: flex;
    flex-direction: column-reverse; 
    }
    .second-img{

        width: 250px;
    }
    .second-img img{
        height: 250px;
    }

        .ourtwo .second-text  {
  
        margin-right: 1rem;
        }
        .ourtwo .second-text h3, .ourtwo .second-text p{
    
            width: 350px;
        }
        .ourtwo .second-text h1{
            font-size:40px ;
       
        }
    
        .ourtwo .second-text h3{
      
            font-size: 15px;
        }
        .ourtwo .second-text button{
            height: 45px;
            width: 150px;
        
        }
}