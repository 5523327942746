.Founder h1{
    font-size: 2rem;
    margin: 2rem 0;
}

.rishi{
    background-color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;
}
.founder-area {
    display: flex;

width: 100%;
}
.founder-area .space{
    width: 50%;
    background-color: white;
    display: flex;
    align-items: center;
}
.founder-area .space1 {
    justify-content:  flex-end;
}
.founder-area .space1 img{
    margin-bottom: 5rem;
   
}
.founder-area .space2 img{
    margin-top: 30rem;
}
@media (max-width:800px) {
    .founder-area .space img{
        display: none;
     }
}
@media (max-width:500px) {
    .rishi p{
       width: 350px; 
    }

}