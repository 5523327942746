.Followus{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.Followus .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.Followus .socials svg {
    font-size: 2.5rem;
    transition: transform 0.3s ease; 
}
.Followus .socials svg:hover {
    transform: scale(1.1); 
}