.Navbar{

    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding-right: 3rem;
    cursor: pointer;


}
.Navbar svg{
    font-size: 1.6rem;
    z-index: 2;
}
.Navbar .login{
    font-weight: bold;
    transition: 0.3s ease-in;
}
.Navbar .login:hover{
    color:#ED5009 ;
}
.Navbar .user-profile{
    height: 30px;
    width: 30px;
    border-radius: 100px;
}
@media (max-width:600px) {
    .Navbar{
        margin-bottom: 0rem;
    
    }
}
@media (max-width:380px) {
    .Navbar{
        margin-bottom: 2rem;
    
    }
}