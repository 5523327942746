.join-us-area{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 1rem;
}
.text-area-join{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.text-area-join strong{
    font-size: 1.5rem;
}
.j-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
@media (max-width:500px) {
    .join-us-area{
       flex-direction: column;
    }
    .text-area-join{
            text-align: start;
            align-items: start;
            padding-right: 30%;
            gap: 0.5rem;
    }
    .j-btn {
        align-items: start;
      
    }
  
}