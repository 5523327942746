.Healthy{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}
.Healthy h1{
    font-size: 2rem;
}
.Healthy p{
text-align: justify;
    width: 850px;
color: #525252;
font-size: 15px;
text-align: justify;
}
.second-div{   
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1rem;

}
.second-div h1{
    color: #ED5009 ;
}
.know{

width: 60%;
position: relative;
}
.know .txt{
    position: absolute;
    top: 30%;
    left: 45% ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 0.5rem;
    color: white;
}
.know .txt p{
    width: 70%;
    color: white;
    font-size: 1.1rem;

}

.know h1{
   font-size: 2rem; 
  
}
.know span{
    font-size: 15px;
   
}
.know .forsmall{
    display: none;
}

@media (max-width:1600px) {
    .know .txt p{
        width: 80%;    
    }
}
@media (max-width:1300px) {
    .know .txt p{
        width: 100%;    
    }
}

@media (max-width:900px) {
    .know{
display: flex;
align-items: center;
justify-content: center;
        }
    .know .forsmall{
        display: flex;
    }
 .know img, .know .txt{
    display: none;
 }
   
  .second-div img{
   height: 250px;
  }
  .Healthy p{
    
    width: 450px;
}
}
@media (max-width:700px) {
    .second-div img{
        height: 200px;
       }
}
@media (max-width:500px) {
    .know .forsmall{
        height: 250px;
    }
    .second-div img{
        height: 150px;
       }
       .second-div{
       padding-bottom: 1rem;
    }
 
}
@media (max-width:420px) {
    .know .forsmall{
        height: 220px;
    }
    .second-div img{
        height: 130px;
       }
    .Healthy p{
    
        width: 90%;
    }
}