.grp{
    display: flex;
    flex-direction: column;
    height: 750px;
    /* background-color: red; */
}

.grp .heading{
    display: flex;
    align-items: center;
    text-align: start;
    padding-left: 2rem;
    font-size: 2rem;
    color: #B20149;
}

.orange{
    background-color: #ED5009;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* height: 600px; */
    margin-top: 250px;
}

.bg-abs{
    position: absolute;
    top: -200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
}
.bg-abs img{
    height: 300px;
}

.org-card{
    background-color: white;
    height: 420px;
    width: 400px;
    border-radius: 1rem ;
    padding: 1.5rem;
    display: flex;
    align-items: start;
    justify-content: space-between;
    text-align: start;
    gap: 1.5rem;
    flex-direction: column;
}
.org-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    width: 100%;
    gap: 1rem;
}
.org-card ul{
    display: flex;
    flex-direction: column;
    gap: 0.6rem;   
}
.org-card-area{
    position: absolute;
    top: 70px;
    display: flex;
    gap: 2rem;
}

@media (max-width:1000px) {
    .org-card-area{
        flex-direction: column;

    }
    .grp{
        height: 1200px;
    }
}
@media (max-width:500px) {
 
    .bg-abs img{
        height: 260px;
    }
    .bg-abs{
        flex-direction: column-reverse;
    }
    .org-card{
        height: 480px;
        width: 300px;      
    }
    .org-card button{
        height: 40px;

    }
    .grp{
        height: 1300px;
    }
}