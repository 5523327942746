
.kids-org-card-area{
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.kid-orange-area{
    background-color: #ED5009;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.kid-orange-area img{
    height: 300px;
    position: absolute;
    left: 20%;
    top: -100px;
}

.kid-or-area{
position: absolute;
right: 20%;
top: -60px;
text-align: start;
display: flex;
flex-direction: column;
gap: 3rem;
}
.kid-grp-area{
    margin-top: 120px ;
    margin-bottom: 230px;
}
@media (max-width:1300px) {

}
@media (max-width:500px) {
    .kid-grp-area{
        margin-bottom: 290px;
    }
    .kid-or-area{
        /* position: absolute;       
        text-align: start;
        display: flex;
        flex-direction: column; */
        top: -17px;
        gap: 1rem;
        right: 0%;
        left: 1%;
        }
    .kid-or-area .koa{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }       
    .kid-or-area .koa p{
        font-size: small;
    }
        .kid-orange-area img{
            height: 300px;
            position: absolute;
         
            right: 0%;
            left: 0%;
            margin-left: 25%;
            
        }
        
   
}