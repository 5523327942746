.StandOut{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;

}
.standout-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    text-align: start;
    width: 450px;
    height: 450px;
    background-color: white;
    border-radius: 1rem;
    border: 1px solid rgb(193, 193, 193);
  box-shadow: 0px 7px 0px rgba(0, 0, 0, 1);

}
.stand-card-area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
}

.standout-card img{
    width: 100%;
}
.standout-txt{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

/* @media (max-width:1400px) {
   
    .standout-card{
        width: 420px;
    }
} */


@media (max-width:700px) {
   
    .standout-card{
        width: 350px;
    }
}
@media (max-width:500px) {
   
    .standout-card{
        width: 300px;
        height: 450px;

    }
}