.Info{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

}

.Info .big-blue-box{
    position: absolute;
    height: 120px;
    left: 0;
    top: -40%;
}
.yellow-arrow{
    position: absolute;
     height: 60px;
     left: 10%;
    
}
.Info .yellow-ball {
    position: absolute;
    height: 60px;
    right: 10%;
    bottom: 0;
}
.Info .peach-box{
    position: absolute; 
    height: 80px;
    bottom: 5%;
    left: -7%;
 }
.Info .info-text{
    position: relative;
    background-color: white;
    width: 50rem;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border-bottom: 2px solid #ED5009;
    border-right: 2px solid #ED5009;
}
.Info .info-text h3{
    width: 40rem;
    z-index: 2;
}
.Info .info-text .blue{
    color: #1877F2 ;
}
@media (max-width:1000px) {
    .Info .info-text{
 
        width: 35rem;

    }
    .Info .info-text h3{
        width: 30rem;
    }
}
.Info .big-blue-box{
    
    height: 100px;
   
}
.Info .yellow-ball {
   
    height: 50px;
   
}
.Info .peach-box{
    
    height: 60px;
   
 }
@media (max-width:600px) {
    .Info .info-text{
 
        width: 28rem;

    }
    .Info .info-text h3{
        width: 23rem;
    }
    .Info .big-blue-box{
        top: -30%;
        height: 90px;
       
    }
    .Info .yellow-ball {
       
        height: 40px;
       
    }
    .Info .peach-box{
        
        height: 50px;
       
     }
     .yellow-arrow{
        
         height: 50px;
         left: 10%;
         top: -50%;
        
    }
}
@media (max-width:450px) {
    .Info{

       width: 93%;
    
    }
    .Info .info-text{
 
        width: 25rem;

    }
    .Info .info-text h3{
        width: 20rem;
    }
}