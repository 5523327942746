.Associations{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: auto;
    margin-bottom: 2rem;
}
.Associations h1{
    font-size: 2.5rem;
}
@media (max-width:800px) {

    .Associations .ass1,  .Associations .ass2,  .Associations .ass3 {
        height: 90px;
    }
   
 
}
@media (max-width:550px) {

    .Associations .ass1,  .Associations .ass2,  .Associations .ass3 {
        height: 55px;
    }
 


}